import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import DashboardLayout, { NoNavLayout } from 'src/layouts/dashboard';

import RequireAuth from './components/RequireAuth';

export const McsPage = lazy(() => import('../pages/mcs'));
export const DetailPage = lazy(() => import('../pages/app'));
export const LoginPage = lazy(() => import('../pages/login'));
export const RegisterPage = lazy(() => import('../pages/register'));
export const RedirectPage = lazy(() => import('../pages/redirect'));
export const PatientsPage = lazy(() => import('../pages/patients'));
export const Page404 = lazy(() => import('../pages/page-not-found'));
export const ScreeningPage = lazy(() => import('../pages/screening'));
export const TherapyPage = lazy(() => import('../pages/therapy'));
export const InvitesPage = lazy(() => import('../pages/invites'));

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      element: (
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      ),
      children: [
        { index: true, element: <RequireAuth element={null}/> },
        { path: 'patients', element: <RequireAuth element={<PatientsPage id="patients" />} /> },
        { path: 'screening', element: <RequireAuth element={<ScreeningPage id="screening" />} /> },
        {
          path: 'details/:patientId',
          element: <RequireAuth element={<DetailPage id="details" />} />,
        },
        {
          path: 'screening/:patientId',
          element: <RequireAuth element={<DetailPage id="screening" />} />,
        },
        { path: 'invites', element: <RequireAuth element={<InvitesPage id="invites" />} /> },
      ],
    },
    {
      element: (
        <NoNavLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </NoNavLayout>
      ),
      children: [
        {
          path: 'patients/redirect/',
          element: <RequireAuth element={<RedirectPage id="redirect" />} />,
        },
        { path: ':lang?/login', element: <LoginPage /> },
        { path: ':lang?/register', element: <RegisterPage /> },
        { path: 'therapy/:flow?/:user', element: <TherapyPage /> },
      ],
    },

    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
